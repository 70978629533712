import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//系统配置
import login from "./modules/system/login";
import menu from "./modules/system/menu";
import dict from "./modules/system/dict";
import setting from "./modules/setting/setting";
import notice from "./modules/system/notice";
import log from "./modules/system/log";
import tagsView from "./modules/tags-view/tagsView";
import userManagement from './modules/system/user-management';
import logManagement from './modules/system/log-management';
import menuManagement from './modules/system/menu-management';
import roleManagement from './modules/system/role-management';
import user from './modules/user/user';
import department from "./modules/system/department";
import dataManagement from "./modules/business/data-management";
import file from "./modules/system/file";
import areaManagement from "./modules/system/area-management";
import tobaccoEnvironment from "./modules/business/tobacco-environment"
import tobaccoQuality from "./modules/business/tobacco-quality";
import thematicMap from './modules/business/thematic-map';
import region from './modules/business/region';
import tobaccoQualityEcharts from "./modules/business/tobacco-quality-echarts";
import tobaccoEnvironmentEcharts from "./modules/business/tobacco-environment-echarts";
import tobaccoAnalyse from "./modules/business/tobacco-analyse";
import tobaccoEcharts from "./modules/business/tobacco-echarts";
import tobaccoManagement from "./modules/business/tobacco-management";
// 业务流程

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		login,
		menu,
		dict,
		setting,
		notice,
		log,
		tagsView,
		userManagement,
		areaManagement,
		logManagement,
		menuManagement,
		roleManagement,
		user,
		department,
		dataManagement,
		file,
		tobaccoEnvironment,
		tobaccoQuality,
		thematicMap,
		region,
		tobaccoQualityEcharts,
		tobaccoEnvironmentEcharts,
		tobaccoAnalyse,
		tobaccoEcharts,
		tobaccoManagement
	}
})
