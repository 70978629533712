import http from "@/utils/http"
import config from "@/config"
export default {
    namespaced:true,
    state:{
        query: {
            menuName: ''
        },
        menuList: [],
        activeMenu: null,
        showInfoDrawer: false,
        parentOfChild: null
    },
    mutations:{
        SET_MENU_LIST(state, value) {
            state.menuList = value;
        },
        SET_ACTIVE_MENU(state, value) {
            state.activeMenu = value;
        },
        SET_DRAWER_SHOW(state, value) {
            state.showInfoDrawer = value;
        },
        SET_PARENT_OF_CHILD(state, value) {
            state.parentOfChild = value;
        },
        SET_QUERY(state, data) {
            state.query = data;
        }
    },
    actions:{
        setQuery({commit}, param) {
            commit("SET_QUERY", param);
        },
        getMenuList({commit, state}) {
            commit("SET_MENU_LIST", []);
            return new Promise(resolve => {
                http.get(config.baseUrl + "menu/tree", {
                    params: state.query
                }).then(resp => {
                    commit("SET_MENU_LIST", resp.data);
                    resolve(resp)
                })
            })
        },
        openInfoDrawer({commit}, activeMenu) {
            commit("SET_ACTIVE_MENU", activeMenu);
            commit("SET_DRAWER_SHOW", true);
        },
        closeInfoDrawer({commit}) {
            commit("SET_ACTIVE_MENU", null);
            commit("SET_PARENT_OF_CHILD", null);
            commit("SET_DRAWER_SHOW", false);
        },
        addNewMenu(context, data) {
            return new Promise(resolve => {
                http.post(config.baseUrl + "menu/add", data).then(resp => {
                    resolve(resp);
                })
            })
        },
        updateMenu(context, data) {
            return new Promise(resolve => {
                http.post(config.baseUrl + "menu/update", data).then(resp => {
                    resolve(resp);
                })
            })
        },
        setParentOfChild({commit}, data) {
            commit("SET_PARENT_OF_CHILD", data);
        },
        deleteMenu(context, data) {
            return new Promise(resolve => {
                http.post(config.baseUrl + "menu/delete", data).then(resp => {
                    resolve(resp);
                })
            })
        }
    }
}