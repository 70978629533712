import http from "@/utils/http"
import config from "@/config"
export default {
    namespaced: true,
    state: {
        logList: [],
        logListTotal: 0,
        activeItem: {}
    },
    mutations: {
        SET_LOG_LIST(state,value) {
            state.logList = value;
        },
        SET_LOG_LIST_TOTAL(state, value) {
            state.logListTotal = value;
        },
        SET_LOG_ACTIVE_ITEM(state, value){
            state.activeItem = value;
        },

    },
    actions: {
        //获取日志列表
        getLogList({commit}, params) {
            commit('SET_LOG_LIST', []);
            commit('SET_LOG_LIST_TOTAL', 0);
            return new Promise( (resolve) => {
                http.get(config.baseUrl + 'log/list', {params}).then( (res) => {
                    if(res.code === 200) {
                        commit('SET_LOG_LIST', res.data.data);
                        commit('SET_LOG_LIST_TOTAL', res.data.total);
                    }
                    resolve(res)
                })
            })
        },
        // 列表选中
        changeActiveItem({commit},value){
            commit('SET_LOG_ACTIVE_ITEM',value);
        },
    }
}