import http from "@/utils/http";
import config from "@/config";

export default {
	namespaced: true,
	state: {
		list: [],
		lists: [],
		total: 0,
		type: '',
		url: '',
		isAll: false,
		targetCheck: '',
		index: 'phValue',
		queryParams: {
			varietiesType: '',
			type: '',
			year: '',
			areaCode: [],
			pageNum: -1,
			grade: '',
			soilType: '',
		},
		urlType: {
			"1": "environment/getEnvironment",
			"2": "environment/getRain",
			"3": "environment/getAirTemperature",
			"4": "environment/getSunshine",
			"5": "environment/getSoilNutrient",
			"6": "environment/getAgriculture",
			"7": "environment/soilHeavy",
			"21": "tobacco-quality/conventional",
			"22": "tobacco-quality/conditionAppearance",
			"23": "tobacco-quality/physical",
			"24": "tobacco-quality/sensory",
			"25": "tobacco-quality/pigment",
			"26": "tobacco-quality/organic",
			"27": "tobacco-quality/neutral"
		},
		colorList: [['#09e9f3', '#99f4ff'],
			['#8d2efe', '#8983ff'],
			['#28b1ff', '#62a8fd'],
			['#f7fc07', '#f2f5a8'],
			['#fe9834', '#e1a161'],
			['#FF359A', '#FFD9EC'],
			['#FF5151', '#FFD2D2'],
			['#02DF82', '#E8FFF5'],
			['#AD5A5A', '#EBD6D6'],
			['#9AFF02', '#DEFFAC'],
			['#FFFF66', '#FFFFF4'],
			['#66FFFF', '#F5FFE8'],
			['#FFA500', '#FFEFD5'],
			['#D2691E', '#F4A460'],
			['#A9A9A9', '#D3D3D3'],
			['#FFD700', '#F0E68C']
		],
		// 选择的详情，根据type来区分，null的时候默认展示视屏
		selectDetail: {
			type: null,
			data: {},
		},
		randomRGBColor: '#7BFF46', // 随机的rgb颜色
	},
	mutations: {
		SET_SELECT_DETAIL(state, value) {
			state.selectDetail = value;
		},
		SET_LIST(state, value) {
			state.list = value;
		},
		SET_LISTS(state, value) {
			state.lists = value;
		},
		SET_TOTAL(state, value) {
			state.total = value;
		},
		SET_QUERY_PARAMS(state, value) {
			state.queryParams = value;
		},
		SET_URL(state, value) {
			state.url = value;
		},
		SET_TYPE(state, value) {
			state.type = value;
		},
		SET_TARGET_CHECK(state, value) {
			state.targetCheck = value;
		},
		SET_IS_ALL(state, value) {
			state.isAll = value;
		},
		SET_INDEX(state, value) {
			state.index = value;
		},
		SET_RANDOM_RGB_COLOR(state, value) {
			state.randomRGBColor = value;
		}
	},

	actions: {
		/**
		 * 获取数据类型
		 * @param state
		 * @param val [list]
		 */
		changeType({commit, state}, val) {
			if (val && val.length > 0) {
				if (val[0] === "1") {
					commit('SET_TYPE', val[1]);
					/*commit("SET_IS_ALL", false);*/
				}
				if (val[0] === "2") {
					commit('SET_TYPE', "2" + val[1]);
					/*commit("SET_IS_ALL", false);*/
				}
			}
			state.queryParams.type = state.type;
			commit('SET_URL', state.urlType[state.type]);
		},

		/**
		 * 查询数据
		 * @param commit
		 * @param state
		 * @returns {Promise<unknown>}
		 */
		search({commit, state}) {
			return new Promise((resolve) => {
				if (state.url) {
					http.post(`${config.baseUrl}` + state.url, state.queryParams)
						.then((res) => {
							if (res.code === 200) {
								commit('SET_LIST', res.data.data);
								commit('SET_TOTAL', res.data.total);
							}else {
								commit('SET_LIST', []);
								commit('SET_TOTAL', 0);
							}
							resolve(res);
						})
				}
			})
		},

		/**
		 * 查询数据,无年份限制专用
		 * @param commit
		 * @param state
		 * @returns {Promise<unknown>}
		 */
		search1({commit, state}) {
			let temp = Object.assign({}, state.queryParams);
			temp.year = '';
			return new Promise((resolve) => {
				commit('SET_LISTS', []);
				if (state.url) {
					http.post(`${config.baseUrl}` + state.url, temp)
						.then((res) => {
							if (res.code === 200) {
								commit('SET_LISTS', res.data.data);
							}
							resolve(res);
						})
				}
			})
		},


		/**
		 * 样点数据查询（地图上专用）：样点list和年份统计lists（echart统计的数据共用一个list，统计在前端处理）
		 * 需要请求两个接口，方便控制数据
		 */
		searchForMap({ state}) {
			if (state.url) {
				return new Promise((resolve) => {
					http.post(`${config.baseUrl}` + state.url, state.queryParams)
						.then((res) => {
							if (res.code === 200 && res.data.data && res.data.data.length > 0) {
								// 查询所有年份的，所有年份统计图使用
								let temp = Object.assign({}, state.queryParams);
								delete temp.year;
								http.post(`${config.baseUrl}` + state.url, temp)
								.then((resAllYear) => {
									if (resAllYear.code === 200 && resAllYear.data.data && resAllYear.data.data.length > 0) {
										// 两个接口的数据集合都不是空，才修改原数据
										// commit('SET_LIST', res.data.data);
										// commit('SET_LISTS', resAllYear.data.data);
										resolve(true);
									} else {
										resolve(false);
									}
								})
							} else {
								resolve(false);
							}
						})
				})
			}
		},

		/**
		 * 修改list的值
		 * @param commit
		 * @param value
		 */
		changeList({commit}, value) {
			commit('SET_LIST', value)
		},

		/**
		 * 修改total的值
		 * @param commit
		 * @param value
		 */
		changeTotal({commit}, value) {
			commit('SET_TOTAL', value)
		},

		/**
		 * 更改参数
		 * @param commit
		 * @param value
		 */
		changeParams({commit}, value) {
			commit('SET_QUERY_PARAMS', value)
		},

		/**
		 * 更改条件对象的某个值
		 * @param state
		 * @param key
		 * @param value
		 */
		changeParamsSingle({state}, {key, value}) {
			state.queryParams[key] = value;
		},

		/**
		 * 更改选中右侧的值
		 * @param commit
		 * @param value
		 */
		changeTargetCheck({commit}, value) {
			commit('SET_TARGET_CHECK', value)
		},

		/**
		 * 更改是否选中全部的状态
		 * @param commit
		 * @param value
		 */
		changeIsAll({commit}, value) {
			commit('SET_IS_ALL', value)
		},

		/**
		 * 更改指标
		 * @param commit
		 * @param value
		 */
		changeIndex({commit}, value) {
			commit('SET_INDEX', value)
		},

		// 更改详情斑块内容
		changeSelectDetail({commit}, value) {
			commit('SET_SELECT_DETAIL', value);
		},

		// 更改随机颜色
		setRandomRGBColor({commit}, value) {
			commit('SET_RANDOM_RGB_COLOR', value);
		}
	},
}

