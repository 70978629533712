//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuTree from "./MenuTree.vue";
import { menuTitle } from "@/config";
export default {
	data() {
		return {
			menuTitle,
		};
	},
	components: {
		MenuTree,
	},
	computed: {
		// 菜单列表
		menuList() {
            return this.$store.getters["login/doUserInfo"].menuList;
		},

		// 菜单主题
		menuTheme() {
			return this.$store.state.setting.systemConfig.menuTheme;
		},

		menuDarkColor() {
			return this.$store.state.setting.systemConfig.menuDarkColor;
		},
	},
	methods: {
		// 跳转路由
		menuSelect(key) {
			this.$router.push("/" + key);
		},
	},
};
