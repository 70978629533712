//
//
//
//

import * as echarts from "echarts";
import resize from "./resize";
export default {
    name: 'liu-charts',
	mixins: [resize],
	props: {
        options:{
            required: true,
            type: Object,
            default: ()=>({})
        },
		className: {
			type: String,
			default: "chart_"+Math.random(),
		},
		width: {
			type: String,
			default: "100%",
		},
		height: {
			type: String,
			default: "350px",
		},

        /**
         * String dark 目前只支持dark主题
         * Object 从 https://echarts.apache.org/zh/theme-builder.html 下载主题
         */
        theme: [String, Object],

        /**
         * devicePixelRatio?: number,
         * renderer?: string,
         * useDirtyRect?: boolean, // 从 `v5.0.0` 开始支持
         * width?: number|string,
         * height?: number|string,
         * locale?: string
         */
        opts:{
            type: Object,
            defaulut: ()=>({})
        }
	},
	data() {
		return {
			chart: null,
            echarts
		};
	},
	watch: {
		options: {
			deep: true,
			handler(val) {
                if(this.chart){
                    this.chart.setOption(val, true);
                }
			},
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.initChart();
		});
	},
	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	methods: {
		initChart() {
            // 如果是字符串采用 echarts 定义好的主题
            if(this.theme instanceof String){
                require(`echarts/theme/${this.theme}`);
            }
			this.chart = echarts.init(this.$el, this.theme, this.opts);
            this.chart.setOption(this.options, true);
		}
	},
};
