//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VerticalMenu from "../menu/VerticalMenu.vue";
import HorizontalMenu from "../menu/HorizontalMenu.vue";
import HeaderDropdown from "./HeaderDropdown.vue";
import TagsView from "../tags-view";
import { title } from "@/config";
export default {
	data() {
		return {
			title,
		};
	},
	components: { VerticalMenu, HorizontalMenu, HeaderDropdown, TagsView },
	computed: {
		menuDirection() {
			return this.$store.state.setting.systemConfig.menuDirection;
		},

		keepAlive() {
			return this.$route.meta.keepAlive;
		},

		isCollapse() {
			return this.$store.state.setting.isCollapse;
		},

		tagsView() {
			return this.$store.state.setting.systemConfig.tagsView;
		},

		fixedHeader() {
			return this.$store.state.setting.systemConfig.fixedHeader;
		},

		// 计算顶部的header的left值
		headerLeft() {
			let leftValue = "0";
			if (this.fixedHeader) {
				if (this.menuDirection === "horizontal") {
					leftValue = "0";
				}
				if (this.menuDirection === "vertical") {
					if (this.isCollapse) {
						leftValue = "60px";
					} else {
						leftValue = "210px";
					}
				}
			}
			return leftValue;
		},

		// 左侧菜单固定
		leftNavFixed() {
			return this.$store.state.setting.systemConfig.leftNavFixed;
		},
	},
};
