import http from "@/utils/http"
import config from "@/config"
import Vue from "vue"
export default {
    namespaced: true,
    state:{
        userInfo: {},
    },
    mutations:{
        SET_USER_INFO(state,value){
            state.userInfo = value
        },
    },
    getters:{
        doUserInfo(state){
            if(state.userInfo.id){
                return state.userInfo
            }else{
                return Vue.ls.get('userInfo', {})
            }
        }
    },
    actions: {
        //登录
        userLogin(context, fromData) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + "user/login", fromData).then((res) => {
                    resolve(res);
                })
            })
        },

        //退出登录
        loginOut() {
            return new Promise((resolve) => {
                http.post(config.baseUrl + "logout").then((res) => {
                    resolve(res);
                })
            })
        },

        //获取用户信息
        getUserInfo({commit}){
            return new Promise((resolve)=>{
                http.get(config.baseUrl + "user/info").then((res)=>{
                    if(res.code===200){
                        Vue.ls.set('userInfo', res.data)
                        commit('SET_USER_INFO', res.data)
                    }
                    resolve(res);
                })
            })
        },
    }
}