import systemConfig from "@/config"
export default {
    namespaced:true,
    state:{
        systemConfig: JSON.parse(JSON.stringify(systemConfig)),

        // 左侧菜单折叠
        isCollapse: false
    },
    mutations:{
        SET_SYSTEM_CONFIG(state, {key, value}){
            // eslint-disable-next-line no-prototype-builtins
            if(state.systemConfig.hasOwnProperty(key)){
                state.systemConfig[key] = value
            }
        },

        // 设置多个属性
        SET_SYSTEM_CONFIGS(state, obj){
            for(let key in obj) {
                state.systemConfig[key] = obj[key]
            }
        },
        
        SET_IS_COLLAPSE(state, value) {
            state.isCollapse = value
        }
    }
}