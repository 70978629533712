import http from "@/utils/http"
import config from "@/config"
export default {
    namespaced: true,
    state: {
        dictList: [],
        activeItem: null,
        total: 0,
        parentMenuList: [],
        dictEntity: [],
    },
    mutations: {
        DICT_TABLE_LIST(state, value) {
            state.dictList = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        DICt_ACTIVE_ITEM(state, value) {
            state.activeItem = value;
        },
        DICT_ENTITY(state, value) {
            state.dictEntity = value;
        },

    },
    actions: {
        /**
         * 获取字典列表
         * @param commit
         * @param params
         * @returns {Promise<unknown>}
         */
        getDictList({commit}, params) {
            return new Promise((resolve) => {
                commit('DICT_TABLE_LIST', []);
                commit('SET_TOTAL', 0);
                http.get(`${config.baseUrl}dict/getDictInfoByPage`, {params}).then((res) => {
                    if (res.code === 200) {
                        commit('DICT_TABLE_LIST', res.data.data);
                        commit('SET_TOTAL', res.data.total);
                    }
                    resolve(res);
                })
            })
        },

        /**
         * 添加字典
         * @param context
         * @param dictVO
         * @returns {Promise<unknown>}
         */
        addDictInformation(context, dictVO) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}dict/add`, dictVO).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 删除字典
         * @param context
         * @param ids
         * @returns {Promise<unknown>}
         */
        removeDictInformation(context, ids) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}dict/delete`, ids).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 修改字典
         * @param context
         * @param dictVO
         * @returns {Promise<unknown>}
         */
        modifyDictInformation(context, dictVO) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}dict/update`, dictVO).then((res) => {
                    resolve(res)
                })
            })
        },
        /**
         * 根据id查询字典列表信息
         * @param params
         * @returns {Promise<unknown>}
         */
        getDictEntity({commit}, params) {
            return new Promise((resolve) => {
                commit('DICT_ENTITY', []);
                http.post(`${config.baseUrl}dict/getDictEntity`, params).then((res) => {
                    if (res.code === 200) {
                        commit('DICT_ENTITY', res.data);
                    }
                    resolve(res);
                })
            })
        },

        /**
         * 选中列表
         * @param commit
         * @param value
         */
        changeActiveItem({commit}, value) {
            commit('DICt_ACTIVE_ITEM', value)
        },


    }
}
