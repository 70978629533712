
export default {
	namespaced: true,
	state: {
        administrativeDivision: {}, // 行政区划
		thematicLayersAndStylesObj: {},
        thematicTypeList: [],
        selectedThematicType: '土壤氮', // 选中的专题图类型
	},
	mutations: {
        SET_ADMINISTRATIVE_DIVISION(state, value) {
            state.administrativeDivision = value;
        },
		SET_THEMATIC_LAYERS_AND_STYLES_OBJ(state, value) {
			state.thematicLayersAndStylesObj = value;
		},
        SET_THEMATIC_TYPE_LIST(state, value) {
            state.thematicTypeList = value;
        },
        SET_SELECTED_THEMATIC_TYPE(state, value) {
            state.selectedThematicType = value;
        },
	},
	actions: {
        setAdministrativeDivision({commit}) {
            // 包含上级信息的
            let value = {
                '1': {
                    discription: '云南省届',
                    layers: '8ecd9a90fa316e74538d87122bb8bc2a',
                },
                '2': {
                    discription: '云南市届',
                    layers: 'bca85b14bf90b602fededfdeabf740e7',
                },
                '3': {
                    discription: '云南区县',
                    layers: '4d6f257d63df0a311de47cb5eca38bd4',
                },
                '4': {
                    discription: '云南乡镇',
                    layers: 'bc431dc8547292c83860b1f69209ecf7',
                },
                'tobaccoArea': {
                    discription: '种植区',
                    layers: '09bb9492478932f642a3aa7cf3e2a9ba'
                }
            }
            commit('SET_ADMINISTRATIVE_DIVISION', value);
        },
		setThematicLayersAndStylesObj({commit}, {year, disableZZQ}) {
            let obj = {
                '2020': {
                    '土壤氮': {
                        layers: '0275bdf894576bf13be72ca38beddf63',
                        STYLES: '6be104c3ad5b8bba92b446215b70d2ad',
                    },
                    '种植区': {
                        layers: '7568070c6427f978c89d047aed5bfe20',
                        STYLES: '8b03bb6cd257d73faa6b62e3c227ce84',
                        disable: disableZZQ,
                    },
                    '品质特点': {
                        layers: '328d1f1acd821f38c3aeb592332634e0',
                        STYLES: 'd10b144932075aeb3490bbb3ddc6e4f1',
                    },
                    '日照': {
                        layers: '489dd8d2af6dfb9ed7ad788361b33aaa',
                        STYLES: '3b1a8f54f84c537c131181a7af350421',
                    },
                    '有机质': {
                        layers: '72da6b93c9d8f1d9c6367d969bb8aa95',
                        STYLES: '3ebf4af8ea42efeaec4831a1b08fc421',
                    },
                    '土壤钾': {
                        layers: 'bd40eb65a97e70415a8771b7987f951a',
                        STYLES: '7ac7e87eba93e74b8c37d69d7e176c48',
                    },
                    '降雨量': {
                        layers: '6d41ca7736aaeaf5ebb62421e1a6b15f',
                        STYLES: '45b7e70dc48a1ea227bbe3a0dfc623b9',
                    },
                },
            };
            commit('SET_THEMATIC_LAYERS_AND_STYLES_OBJ', obj[year]);
            let list = [];
            for (const key in obj[year]) {
                if (!obj[year][key].disable) {
                    list.push(key);
                }
            }
            commit('SET_THEMATIC_TYPE_LIST', list);
        },

        setSelectedThematicType({commit}, value) {
            commit('SET_SELECTED_THEMATIC_TYPE', value);
        }
	}
}