import http from "@/utils/http";
import config from "@/config";

export default {
	namespaced: true,
	state: {
		pointerList: [],
		dialogVisible: false,
		tagsList: [],
		type: '',
		list: [],
		total: 0,
		exportFlag: false,
		selectList: [],
		queryParams: {
			year: '',
			soilType: '',
			areaCode: [],
			pageSize: 10,
			pageNum: 1,
			index: []
		},
		headCheck: '1'
	},
	mutations: {
		SET_POINTER_LIST(state, value) {
			state.pointerList = value;
		},
		SET_TAGS_LIST(state, value) {
			state.tagsList = value;
		},
		SET_DIALOG_VISIBLE(state, value) {
			state.dialogVisible = value;
		},
		TYPE(state, value) {
			state.type = value;
		},
		SET_LIST(state, value) {
			state.list = value;
		},
		SET_TOTAL(state, value) {
			state.total = value;
		},
		SET_QUERY_PARAMS(state, value) {
			state.queryParams = value;
		},
		SET_SELECT_LIST(state, value) {
			state.selectList = value;
		},
		SET_EXPORT_FLAG(state, value) {
			state.exportFlag = value;
		},
		SET_HEAD_CHECK(state, value) {
			state.headCheck = value;
		},
	},

	actions: {
		changePointerList({commit}, value) {
			commit('SET_POINTER_LIST', value)
		},

		/**
		 * 更改标签
		 * @param commit
		 * @param value
		 */
		changeTagsList({commit}, value) {
			commit('SET_TAGS_LIST', value);
		},

		/**
		 * 更改对话框的状态
		 * @param commit
		 * @param value
		 */
		changeDialogVisible({commit}, value) {
			commit('SET_DIALOG_VISIBLE', value);
		},

		// 获取气温信息
		search({commit, state}, url) {
			return new Promise((resolve) => {
				commit('SET_LIST', []);
				commit('SET_TOTAL', 0);
				http.post(`${config.baseUrl}` + url, state.queryParams)
					.then((res) => {
						if (res.code === 200) {
							commit('SET_LIST', res.data.data);
							commit('SET_TOTAL', res.data.total);
						}
						resolve(res);
					})
			})
		},

		/**
		 * 修改list的值
		 * @param commit
		 * @param value
		 */
		changeList({commit}, value) {
			commit('SET_LIST', value)
		},

		/**
		 * 修改total的值
		 * @param commit
		 * @param value
		 */
		changeTotal({commit}, value) {
			commit('SET_TOTAL', value)
		},

		/**
		 * 更改参数
		 * @param commit
		 * @param value
		 */
		changeParams({commit}, value) {
			commit('SET_QUERY_PARAMS', value)
		},

		/**
		 * 更改条件对象的某个值
		 * @param state
		 * @param key
		 * @param value
		 */
		changeParamsSingle({state}, {key, value}) {
			state.queryParams[key] = value;
		},

		/**
		 * 选中列表
		 * @param commit
		 * @param value
		 */
		changeType({commit}, value) {
			commit('TYPE', value);
		},


		/**
		 * 修改selectList的值
		 * @param commit
		 * @param value
		 */
		changeSelectList({commit}, value) {
			commit('SET_SELECT_LIST', value);
		},

		/**
		 * 修改exportFlag的值
		 * @param commit
		 * @param value
		 */
		changeExportFlag({commit}, value) {
			commit('SET_EXPORT_FLAG', value);
		},

		/**
		 * 修改头的值
		 * @param commit
		 * @param value
		 */
		changeHeadCheck({commit}, value) {
			commit('SET_HEAD_CHECK', value);
		},
	},
}

