//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import systemConfig from "@/config";
import store from "@/store";
import generateColors from "@/assets/scripts/color";
import ChangePassword from '@/components/layouts/change-password'
import PersonalInformation from '@/components/layouts/personal-information'
export default {
	components: {
		ChangePassword,
		PersonalInformation,
	},
	data() {
		return {
			imageUrl: systemConfig.imageUrl,
			settingVisible: false,
			userInfoVisible: false,
			selectTabPane: 0,
			colors: {
				primary: store.state.setting.systemConfig.primaryColor,
			},
			originalStyle: "",
		};
	},
	computed: {
		menuDirection() {
			return this.$store.state.setting.systemConfig.menuDirection;
		},
		menuTheme() {
			return this.$store.state.setting.systemConfig.menuTheme;
		},
		menuDarkColor: {
			get() {
				return this.$store.state.setting.systemConfig.menuDarkColor;
			},
			set(value) {
				this.$store.commit("setting/SET_SYSTEM_CONFIG", {
					key: "menuDarkColor",
					value: value,
				});
			},
		},
		tagsView: {
			get() {
				return this.$store.state.setting.systemConfig.tagsView;
			},
			set(value) {
				this.$store.commit("setting/SET_SYSTEM_CONFIG", {
					key: "tagsView",
					value: value,
				});
			},
		},
		fixedHeader: {
			get() {
				return this.$store.state.setting.systemConfig.fixedHeader;
			},
			set(value) {
				this.$store.commit("setting/SET_SYSTEM_CONFIG", {
					key: "fixedHeader",
					value: value,
				});
			},
		},
        leftNavFixed: {
			get() {
				return this.$store.state.setting.systemConfig.leftNavFixed;
			},
			set(value) {
				this.$store.commit("setting/SET_SYSTEM_CONFIG", {
					key: "leftNavFixed",
					value: value,
				});
			},
		},
		userInfo() {
			return this.$store.getters["login/doUserInfo"];
		},
	},
	methods: {
		showUserDrawer(bool) {
			this.userInfoVisible = bool;
		},
		/*openLink() {
			window.open('http://aos.airag.cn/cn/login');
		},*/
		// 退出登录
		signOut() {
			this.$confirm("此操作将退出登录, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				this.$ls.set("userInfo", {});
				this.$ls.set("JWT_TOKEN", "");
				this.$router.push("/login");
			})
			.catch(() => {});
		},

		// 切换导航模式 侧边 顶部
		changeMenuDirection(type) {
			this.$store.commit("setting/SET_SYSTEM_CONFIG", {
				key: "menuDirection",
				value: type,
			});
			this.$ls.set("menuDirection", type);
		},

		// 切换菜单整体风格 暗色 亮色
		changeMenuTheme(type) {
			this.$store.commit("setting/SET_SYSTEM_CONFIG", {
				key: "menuTheme",
				value: type,
			});
			this.$ls.set("menuTheme", type);
		},

		// 修改暗色菜单的主题
		changeMenuDarkColor(color) {
			this.menuDarkColor = color;
			this.$ls.set("menuDarkColor", color);
		},

		// 切换主题色
		changePrimaryColor(primary) {
			this.colors = {
				primary,
			};
			this.colors = Object.assign(
				{},
				this.colors,
				generateColors(this.colors.primary)
			);
			this.writeNewStyle();

			// 存储设置的值
			this.$store.commit("setting/SET_SYSTEM_CONFIG", {
				key: "primaryColor",
				value: primary,
			});
			this.$ls.set("primaryColor", primary);

            //window.location.reload();
		},

		// 重写style, 并添加在head
		writeNewStyle() {
			let cssText = this.originalStyle;
			Object.keys(this.colors).forEach((key) => {
				cssText = cssText.replace(
					new RegExp("(:|\\s+)" + key, "g"),
					"$1" + this.colors[key]
				);
			});

			this.$ls.set("elementui_style", cssText);

            let elementStyle = document.getElementById('create_element_style');
			if (elementStyle) {
                elementStyle.innerText = cssText
			} else {
                const style = document.createElement("style");
                style.id = "create_element_style";
				style.innerText = cssText;
				document.head.appendChild(style);
			}
		},

		// 获取elementui的样式
		getIndexStyle() {
			let themeChalk = require("@/assets/scripts/theme-chalk");
			this.originalStyle = this.getStyleTemplate(themeChalk.css);
		},

		// 匹配颜色
		getStyleTemplate(data) {
			const colorMap = {
				"#3a8ee6": "shade-1",
				"#409eff": "primary",
				"#53a8ff": "light-1",
				"#66b1ff": "light-2",
				"#79bbff": "light-3",
				"#8cc5ff": "light-4",
				"#a0cfff": "light-5",
				"#b3d8ff": "light-6",
				"#c6e2ff": "light-7",
				"#d9ecff": "light-8",
				"#ecf5ff": "light-9",
			};
			Object.keys(colorMap).forEach((key) => {
				const value = colorMap[key];
				data = data.replace(new RegExp(key, "ig"), value);
			});
			return data;
		},

		// 设置多页签模式
		changeTagsView(bool) {
			this.$ls.set("tagsView", bool);
		},

		// 切换下拉顶部是否固定
		changeFixedHeader(bool) {
			this.$ls.set("fixedHeader", bool);
		},

        // 切换左侧导航是否固定
        changeLeftNavFixed(bool) {
            this.$ls.set("leftNavFixed", bool);
        },

		// 还原设置
		resetSet() {
			this.$confirm("此操作将还原所有的界面设置, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					// 还原导航模式
					this.$store.commit("setting/SET_SYSTEM_CONFIG", {
						key: "menuDirection",
						value: systemConfig.menuDirection,
					});
					this.$ls.set("menuDirection", systemConfig.menuDirection);

					// 还原导航风格
					this.$store.commit("setting/SET_SYSTEM_CONFIG", {
						key: "menuTheme",
						value: systemConfig.menuTheme,
					});
					this.$ls.set("menuTheme", systemConfig.menuTheme);

					// 还原导航背景
					this.menuDarkColor = systemConfig.menuDarkColor;
					this.$ls.set("menuDarkColor", systemConfig.menuDarkColor);

					// 还原 element style
					this.changePrimaryColor(systemConfig.primaryColor);
				})
				.catch(() => {});
		},
	},

	mounted() {
		this.getIndexStyle();
	},
};
