import http from "@/utils/http";
import config from "@/config";

export default {
	namespaced: true,
	state: {
		activeItem: null,
		select: false,
	},
	mutations: {
		ACTIVE_ITEM(state, value) {
			state.activeItem = value;
		},
		SET_SELECT(state, value) {
			state.select = value;
		},
	},
	actions: {
		/**
		 * 选中列表
		 * @param commit
		 * @param value
		 */
		changeActiveItem({commit}, value) {
			commit('ACTIVE_ITEM', value);
		},

		/**
		 * 修改select的状态
		 * @param commit
		 * @param value
		 */
		changeSelect({commit}, value) {
			commit('SET_SELECT', value);
		},

		/**
		 * 删除数据
		 * @param context
		 * @param delReqVO
		 */
		delete(context, delReqVO) {
			return new Promise((resolve) => {
				http.post(`${config.baseUrl}dataManagement/deleteByIds`, delReqVO).then((res) => {
					resolve(res)
				})
			})
		},
	}
}