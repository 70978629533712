import http from "@/utils/http"
import config from "@/config"

export default {
    namespaced: true,
    state: {
        activeUserInfo: {},
    },
    mutations: {
        SET_ACTIVE_USER_INFO(state, value) {
            state.activeUserInfo = value;
        },
    },
    actions: {
        /**
         * 获取当前登录用户的信息
         * @param commit
         * @returns {Promise<unknown>}
         */
        getActiveUserInfo({commit}) {
            commit('SET_ACTIVE_USER_INFO', {});
            return new Promise((resolve) => {
                http.get(config.baseUrl + `user/info`).then((res) => {
                    if (res.code === 200) {
                        commit('SET_ACTIVE_USER_INFO', res.data);
                    }
                    resolve(res);
                })
            })
        },

        changePassword(context, changePasswdReqVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/changePassword`, changePasswdReqVO).then((res) => {
                    resolve(res);
                })
            })
        },
    }
}