import http from "@/utils/http"
import config from "@/config"
import Vue from "vue"
export default {
    namespaced: true,
    state: {
        tableList: [],
        activeItem: null,
        menuList: [],
    },
    getters:{
        doMenuList(state){
            if(state.menuList.length>0){
                return state.menuList
            }else{
                return Vue.ls.get('menu_list', [])
            }
        }
    },
    mutations: {
        SET_TABLE_LIST(state, value) {
            state.tableList = value
        },

        SET_ACTIVE_ITEM(state, value) {
            state.activeItem = value
        },

        SET_MENU_LIST(state, value){
            state.menuList = value
        }
    },
    actions: {
        //获取菜单列表
        getList({ commit }, params) {
            return new Promise((resolve) => {
                commit('SET_TABLE_LIST', []);
                http.get(`${config.baseUrl}menu/tree`, { params }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_TABLE_LIST', res.data);
                    }
                    resolve(res);
                })
            })
        },

        //获取纯菜单列表 没有按钮
        getMenuList({commit}) {
            return new Promise((resolve) => {
                http.get(`${config.baseUrl}menu/tree`, { params: { type: 0 } }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_MENU_LIST', res.data);
                        Vue.ls.set('menu_list', res.data)
                        resolve(res.data)
                    } else {
                        commit('SET_MENU_LIST', []);
                        resolve([])
                    }
                })
            })
        },

        //添加菜单
        add(context, addData) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/menu/add`, addData).then((res) => {
                    resolve(res)
                })
            })
        },

        //删除菜单
        remove(context, id) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/menu/deleteByIds`, [id]).then((res) => {
                    resolve(res)
                })
            })
        },

        //修改菜单
        modify(context, modifyData) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/menu/update`, modifyData).then((res) => {
                    resolve(res)
                })
            })
        },

        // 列表选中
        changeActiveItem({ commit }, value) {
            commit('SET_ACTIVE_ITEM', value)
        },
    }
}