import Vue from 'vue'
import VueRouter from 'vue-router'
import routesMap from "./routes"
import Nprogress from "nprogress"

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: routesMap
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

router.beforeEach( async (to, from, next) => {
    Nprogress.start();
    next()
    Nprogress.done();
})

export default router