import http from "@/utils/http"
import config from "@/config"

export default {
	namespaced: true,
	state: {
		areaTree: [],
		areaList: []
	},
	mutations: {
		SET_AREA_TREE(state, value) {
			state.areaTree = value;
		},
		SET_AREA_LIST(state, value) {
			state.areaList = value;
		}
	},
	actions: {
		// 获取行政区划树
		getAreaTree({commit}) {
			commit('SET_AREA_TREE', []);
			return new Promise(resolve => {
				http.get(config.baseUrl + 'areaCode/getTree').then(res => {
					if (res.code === 200) {
						commit('SET_AREA_TREE', res.data);
					}
					resolve(res);
				})

			});
		},

		// 根据行政区编码获取信息
		getAreaByCode(content, code) {
			return new Promise(resolve => {
				http.get(config.baseUrl + `/areaCode/getByCode/${code}`).then(res => {
					if (res.code === 200) {
						resolve(res.data);
					}
				})
			})
		},

		// 根据行政区编码修改描述信息
		updateDescribe(comtent, item) {
			return new Promise(resolve => {
				http.post(config.baseUrl + `/areaCode/updateDescribe`, item).then(res => {
					if (res.code === 200) {
						resolve(res);
					}
				})
			})
		},
	}
}
