import axios from "axios"
import router from "../router"
import {encryptByAES, decryptByAES} from "./crypto"
import Vue from "vue"

const http = axios.create();

// 超时时间1分钟超时
http.defaults.timeout = 1000 * 60
http.interceptors.request.use(config => {
	config.headers.Authorization = Vue.ls.get('JWT_TOKEN');
	config.headers['Content-Type'] = 'application/json;charset=UTF-8';
	// data 加密
	if (config.data) {
		// 对象才加密，数组不加密
		if (config.data.constructor === Object) {
			let tempData = Object.assign({}, config.data);
			config.data = {data: encryptByAES(JSON.stringify(tempData))}
		}
	}
	// params 加密
	if (config.params) {
		let tempData = Object.assign({}, config.params);
		config.params = {data: encryptByAES(JSON.stringify(tempData))}
	}

	// url 加密
	// if (config.url.indexOf('?') > 0) {
	//     let urlPre = config.url.substr(0, config.url.indexOf('?'));
	//     let tempUrl = config.url.substr(config.url.indexOf('?') + 1);
	//     let dec = encodeURIComponent(encryptByAES(tempUrl));
	//     config.url = urlPre + '?data=' + dec;
	// }
	return config;
}, error => {
	return Promise.reject(error)
});

http.interceptors.response.use(function (response) {
	if (response.data.code === 401) {
		router.push("/login");
		return {
			code: 401,
			data: null,
			msg: response.data.msg
		}
	} else {
		// data解密
		if (typeof (response.data.data) === 'string') {
			response.data.data = decryptByAES(response.data.data);
		}
		return response.data;
	}
}, function (error) {
	return Promise.reject(error)
});

export default http