//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "change-password",
    inject: ['showLoading', 'hideLoading'],
    computed: {
        activeUserInfo() {
            return this.$store.state['user'].activeUserInfo;
        }
    },
    data() {
        let validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.addForm.passwordRe !== '') {
                    this.$refs.addForm.validateField('passwordRe');
                }
                callback();
            }
        };
        let validatePasswordRe = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.addForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            addForm: {
                oldPassword: '',
                passwordRe: '',
                password: ''
            },
            rules: {
                oldPassword: [
                    {required: true, message: '请输入旧密码', trigger: 'blur'}
                ],
                password: [
                    {required: true, validator: validatePassword, trigger: 'blur'}
                ],
                passwordRe: [
                    {required: true, validator: validatePasswordRe, trigger: 'blur'}
                ]
            }
        };
    },
    async mounted() {
        await this.$store.dispatch('user/getActiveUserInfo');
    },
    methods: {
        //提交新增数据
        submitForm() {
            this.$refs["addForm"].validate(async (valid) => {
                if (valid) {
                    this.$confirm(`是否确认修改密码?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(async () => {
                        this.showLoading();
                        let addRes = await this.$store.dispatch("user/changePassword", this.addForm);
                        if (addRes.code === 200) {
                            this.$notify({
                                title: addRes.code,
                                message: '修改成功，请重新登录',
                                type: 'success',
                                duration: 2000,
                                position: "bottom-right"
                            });
                            // 密码修改成功之后需要退出到登录界面重新登录
                            sessionStorage.removeItem("qy__userInfo");
                            sessionStorage.removeItem('qy__JWT_TOKEN');
                            await this.$router.push('/login');
                        } else {
                            this.$notify({
                                title: addRes.code,
                                message: addRes.msg,
                                type: 'error',
                                duration: 2000,
                                position: "bottom-right"
                            });
                        }
                        this.hideLoading();
                    }).catch(() => {
                    });
                }
            });
        },
        resetForm() {
            this.$refs["addForm"].resetFields();
        },
    }
}
