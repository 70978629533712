import {UserLayout, GlobalLayout} from '@/components/layouts'

export default [
	{
		path: '/',
		name: 'root',
		component: UserLayout,
		meta: {title: '首页'},
		redirect: '/login',
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import('@/views/login/index.vue'),
				meta: {title: '登录'},
				children: []
			},
		]
	},
	{
		path: '/system',
		name: 'system',
		component: GlobalLayout,
		children: [
			{
				path: 'dict',
				name: 'dict',
				meta: {title: '字典管理'},
				component: () => import('@/views/system/dict/index.vue'),
				children: []
			},
			{
				path: 'department',
				name: 'department',
				meta: {title: '部门管理'},
				component: () => import('@/views/system/department/index.vue'),
				children: []
			},
			{
				path: 'menu-management',
				name: 'menu-management',
				meta: {title: '菜单管理'},
				component: () => import('@/views/system/menu-management/index.vue'),
				children: []
			},
			{
				path: 'user-management',
				name: 'user-management',
				meta: {title: '用户管理'},
				component: () => import('@/views/system/user-management/index.vue'),
				children: []
			},
			{
				path: 'role-management',
				name: 'role-management',
				meta: {title: '角色管理'},
				component: () => import('@/views/system/role-management/index.vue'),
				children: []
			},
			{
				path: 'log-management',
				name: 'log-management',
				meta: {title: '日志管理'},
				component: () => import('@/views/system/log-management/list.vue'),
				children: []
			},
			{
				path: 'area-management',
				name: 'area-management',
				meta: {title: '行政区划管理'},
				component: () => import('@/views/system/area-management/list.vue'),
				children: []
			}

		]
	},
	{
		path: '/business',
		name: 'business',
		component: GlobalLayout,
		children: [
			{
				path: 'data-management',
				name: 'data-management',
				meta: {title: '数据管理'},
				component: () => import('@/views/business/data-management/index.vue'),
				children: []
			},
			{
				path: 'tobacco-quality',
				name: 'tobacco-quality',
				meta: {title: '烟叶质量'},
				component: () => import('@/views/business/tobacco-quality/index.vue'),
				children: []
			},
			{
				path: 'tobacco-environment',
				name: 'tobacco-environment',
				meta: {title: '植烟环境'},
				component: () => import('@/views/business/tobacco-environment/index.vue'),
				children: []
			},
			{
				path: 'search-analysis',
				name: 'search-analysis',
				meta: {title: '全局检索分析'},
				component: () => import('@/views/business/search-analysis/index.vue'),
				children: []
			},
			{
				path: 'roller-shutter-contrast',
				name: 'roller-shutter-contrast',
				meta: {title: '卷帘对比'},
				component: () => import('@/views/business/roller-shutter-contrast/index.vue'),
				children: []
			},
			{
				path: 'thematic-map',
				name: 'thematic-map',
				meta: {title: '专题图展示'},
				component: () => import('@/views/business/thematic-map/index.vue'),
				children: []
			},
            {
                path: 'data-model',
                name: 'data-model',
                meta: {title: '智慧模型'},
                component: () => import('@/views/business/data-model/index.vue'),
                children: []
            },
			{
				path: 'expert-consulting',
				name: 'expert-consulting',
				meta: {title: '专家咨询服务'},
				component: () => import('@/views/business/expert-consulting/index.vue'),
				children: []
			},
			{
				path: 'expert-consulting',
				name: 'expert-consulting',
				meta: {title: '农资智慧管理'},
				component: () => import('@/views/business/expert-consulting/index.vue'),
				children: []
			},
            // {
            //     path: 'test-iframe',
            //     name: 'test-iframe',
            //     meta: {title: '嵌套页面'},
            //     component: () => import('@/views/business/test.vue'),
			// 	children: []
            // }
		]
	},

	// 404
	{
		path: '/error',
		name: 'error',
		component: GlobalLayout,
		children: [
			{
				path: '404',
				name: '404',
				component: () => import('@/views/error/404.vue'),
			}
		]
	},
	{
		path: '*',
		redirect: '/error/404',
	}
]
