//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuTree from './MenuTree.vue';
import { menuTitle } from "@/config"
export default {
	data() {
		return {
            menuTitle
		};
	},
    components:{
        MenuTree
    },
	computed: {
        isCollapse: {
            get() {
                return this.$store.state.setting.isCollapse;
            },
            set(value) {
                this.$store.commit('setting/SET_IS_COLLAPSE', value)
            }
        },

        // 菜单列表
		menuList() {
			return this.$store.getters["login/doUserInfo"].menuList;
		},

        // 菜单主题
		menuTheme() {
			return this.$store.state.setting.systemConfig.menuTheme;
		},

        // 深色菜单的颜色值
        menuDarkColor() {
            return this.$store.state.setting.systemConfig.menuDarkColor;
        },

        // 左侧菜单固定
        leftNavFixed() {
            return this.$store.state.setting.systemConfig.leftNavFixed;
        }
	},
	methods: {
        // 跳转路由
		menuSelect(key) {
            this.$router.push('/' + key)
		}
	},
};
