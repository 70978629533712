import http from "@/utils/http"
import config from "@/config"

export default {
    namespaced: true,
    state: {
        userList: [],
        userTotal: 0,
        activeItem: {},
        userRoleList: [],
        activeItemForRole: {}, // 角色授权适用
        userRoleInfo: [], // 用户授权使用
    },
    mutations: {
        SET_USER_TABLE_LIST(state, value) {
            state.userList = value;
        },
        SET_USER_LIST_TOTAL(state, value) {
            state.userTotal = value;
        },
        SET_USER_ACTIVE_ITEM(state, value) {
            state.activeItem = value;
        },
        SET_USER_ACTIVE_ITEM_FOR_ROLE(state, value) {
            state.activeItemForRole = value;
        },
        SET_USER_ROLE_LIST(state, value) {
            state.userRoleList = value;
        },
        SET_USER_ROLE_INFO(state, value) {
            state.userRoleInfo = value;
        }
    },
    actions: {
        //查询用户信息列表
        getAllUserList({commit}, params) {
            commit('SET_USER_TABLE_LIST', []);
            commit('SET_USER_LIST_TOTAL', 0);
            return new Promise((resolve) => {
                http.get(config.baseUrl + 'user/userList', {
                    params
                }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_USER_TABLE_LIST', res.data.data);
                        commit('SET_USER_LIST_TOTAL', res.data.total);
                    }
                    resolve(res);
                })
            })
        },

        //新增用户信息
        addUserInformation(context, informationVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/createUser`, informationVO).then((res) => {
                    resolve(res)
                })
            })
        },

        //修改用户信息
        modifyUserInformation(context, informationVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/update`, informationVO).then((res) => {
                    resolve(res)
                })
            })
        },
        //删除用户信息
        removeUserInformation(context, id) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/deleteUser`, id).then((res) => {
                    resolve(res)
                })
            })
        },

        //禁止用户
        prohibitUser(context, params) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/changeStatus`, params).then((res) => {
                    resolve(res)
                })
            })
        },

        // 列表选中
        changeActiveItem({commit}, value) {
            commit('SET_USER_ACTIVE_ITEM', value);
        },

        // 列表选中的用户，角色授权适用
        changeActiveItemForRole({commit}, value) {
            commit('SET_USER_ACTIVE_ITEM_FOR_ROLE', value);
        },

        //查询用户角色列表
        getUserRoleList({commit}, params) {
            commit('SET_USER_ROLE_LIST', []);
            // commit('SET_USER_ROLE_TOTAL', 0);
            return new Promise((resolve) => {
                http.get(config.baseUrl + "/user/roles", {
                    params
                }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_USER_ROLE_LIST', res.data);
                        // commit('SET_USER_ROLE_TOTAL',res.data.total);
                    }
                    resolve(res);
                })
            })
        },

        /**
         * 查看当前用户的角色状态
         * @param commit
         * @param userId
         * @returns {Promise<unknown>}
         */
        getUserRoleInfo({commit}, userId) {
            commit('SET_USER_ROLE_INFO', []);
            return new Promise((resolve) => {
                http.get(config.baseUrl + "user/userRoleInfo/" + userId).then((res) => {
                    if (res.code === 200) {
                        commit('SET_USER_ROLE_INFO', res.data);
                    }
                    resolve(res);
                })
            })
        },

        /**
         * 修改用户权限
         * @param context
         * @param userRoleReqVO
         * @returns {Promise<unknown>}
         */
        changeUserRole(context, userRoleReqVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `user/changeUserRole`, userRoleReqVO).then((res) => {
                    resolve(res)
                })
            })
        },

    }
}