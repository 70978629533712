import http from "@/utils/http"
import config from "@/config"

export default {
    namespaced: true,
    state: {
        roleList: [],
        roleListTotal: 0,
        activeItem: {},
        userList: [],
        allRoleList: [],
    },
    mutations: {
        SET_ROLE_LIST(state, value) {
            state.roleList = value;
        },
        SET_ROLE_LIST_TOTAL(state, value) {
            state.roleListTotal = value;
        },
        SET_ROLE_ACTIVE_ITEM(state, value) {
            state.activeItem = value;
        },
        SET_USER_TABLE_LIST(state, value) {
            state.userList = value;
        },
        SET_ALL_ROLE_LIST(state, value) {
            state.allRoleList = value;
        },
    },
    actions: {
        /**
         * 查询角色信息列表
         * @param commit
         * @param params
         * @returns {Promise<unknown>}
         */
        getAllRoleList({commit}, params) {
            commit('SET_ROLE_LIST', []);
            commit('SET_ROLE_LIST_TOTAL', 0);
            return new Promise((resolve) => {
                http.get(config.baseUrl + 'role/list', {
                    params
                }).then((res) => {
                    if (res.code === 200) {
                        if (params) {
                            commit('SET_ROLE_LIST', res.data.data);
                            commit('SET_ROLE_LIST_TOTAL', res.data.total);
                        } else {
                            commit('SET_ROLE_LIST', res.data);
                        }
                    }
                    resolve(res);
                })
            })
        },

        // 查询所有角色信息列表
        listAllRoleList({commit}) {
            http.get(config.baseUrl + 'role/list').then((res) => {
                if (res.code === 200) {
                    commit('SET_ALL_ROLE_LIST', res.data);
                }
            })
        },

        /**
         * 新增角色信息
         * @param context
         * @param informationVO
         * @returns {Promise<unknown>}
         */
        addRoleInformation(context, informationVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `role/add`, informationVO).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 修改角色信息
         * @param context
         * @param informationVO
         * @returns {Promise<unknown>}
         */
        modifyRoleInformation(context, informationVO) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `role/update`, informationVO).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 删除角色信息
         * @param context
         * @param id
         * @returns {Promise<unknown>}
         */
        removeRoleInformation(context, id) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `role/delete`, id).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 列表选中
         * @param commit
         * @param value
         */
        changeActiveItem({commit}, value) {
            commit('SET_ROLE_ACTIVE_ITEM', value);
        },

        /**
         * 设置角色下的菜单列表
         * @param context
         * @param information
         * @returns {Promise<unknown>}
         */
        setMenuToRole(context, information) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `role/setMenuToRole`, information).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 获取绑定该角色的菜单列表
         * @param context
         * @param roles
         * @returns {Promise<unknown>}
         */
        getAssignMenuList(context, roles) {
            return new Promise((resolve) => {
                http.post(config.baseUrl + `role/getMenuListByRoleIds`, roles).then((res) => {
                    resolve(res)
                })
            })
        },
    }
}