let CryptoJS = require("crypto-js");

let key = "QYtech@GCSqGSIb3"; // 秘钥

//解密
const decryptByAES = (ciphertext) => {
    let decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decString = CryptoJS.enc.Utf8.stringify(decrypt).toString();
    if (decString.charAt(0) === "{" || decString.charAt(0) === "[") {
        //JSON.parse
        decString = JSON.parse(decString);
    }
    return decString;
};

//加密
const encryptByAES = (plaintext) => {
    if (plaintext instanceof Object) {
        //JSON.stringify
        plaintext = JSON.stringify(plaintext)
    }
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), CryptoJS.enc.Utf8.parse(key), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
};

export {
    decryptByAES,
    encryptByAES
}