//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLoading from "./components/BaseLoading.vue";
// import LoadingForMap from "@/components/loading/LoadingForMap.vue";
export default {
	name: "App",
	components: {
		BaseLoading,
		// LoadingForMap,
	},
	provide() {
		return {
			showLoading: this.showLoading,
			hideLoading: this.hideLoading,
			showLoadingForMap: this.showLoadingForMap,
			hideLoadingForMap: this.hideLoadingForMap,
            reload: this.reload
		};
	},
	data() {
		return {
			loadingVisible: false,
			loadingMsg: "",
			loadingForMapVisible: false,
			loadingForMapMsg: '',
			isRouterAlive: true,
			loadingContainerBackgroundColor: '#dedede',
		};
	},
	methods: {
		showLoading(msg, loadingContainerBackgroundColor) {
			if (msg) {
				this.loadingMsg = msg;
			} else {
				this.loadingMsg = "正在加载";
			}
			if (loadingContainerBackgroundColor) {
				this.loadingContainerBackgroundColor = loadingContainerBackgroundColor;
			} else {
				this.loadingContainerBackgroundColor = '#dedede';
			}
			this.loadingVisible = true;
		},
		showLoadingForMap(msg) {
			if (msg) {
				this.loadingForMapMsg = msg;
			} else {
				this.loadingForMapMsg = "正在加载";
			}
			this.loadingForMapVisible = true;
		},
		hideLoading() {
			setTimeout(() => {
				this.loadingVisible = false;
			}, 100);
		},
		hideLoadingForMap() {
			setTimeout(() => {
				this.loadingForMapVisible = false;
			}, 100);
		},

        // 刷新页面
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(function () {
				this.isRouterAlive = true;
			});
		},
	},
};
