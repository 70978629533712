import http from "@/utils/http"
import config from "@/config"
export default {
    namespaced: true,
    state: {
        tableList: [],
        total: 0,
        activeItem: null,
        log: {},
    },
    mutations: {
        SET_TABLE_LIST(state, value) {
            state.tableList = value
        },

        SET_TOTAL(state, value) {
            state.total = value
        },

        SET_ACTIVE_ITEM(state, value) {
            state.activeItem = value
        },

        SET_LOG_ITEM(state, value) {
            state.log = value;
        }
    },
    actions: {
        // 获取日志列表信息
        getList({ commit }, params) {
            return new Promise((resolve) => {
                commit('SET_TABLE_LIST', []);
                commit('SET_TOTAL', 0);
                http.get(`${config.baseUrl}sys/log/getLogInfoByPage`, { params }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_TABLE_LIST', res.data.rows);
                        commit('SET_TOTAL', res.data.total);
                    }
                    resolve(res);
                })
            })
        },

        // 列表选中
        changeActiveItem({ commit }, value) {
            commit('SET_ACTIVE_ITEM', value)
        },

        // 根据id获取日志信息
        getLog({ commit }, params) {
            return new Promise((resolve) => {
                commit('SET_LOG_ITEM', {});
                http.get(`${config.baseUrl}sys/log/getLogInfoById`, { params }).then((res) => {
                    if (res.code === 200) {
                        commit('SET_LOG_ITEM', res.data);
                    }
                    resolve(res);
                })
            })
        },
    }
}