/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import './icons'

// 引入css
import "./assets/styles/base.css"
// 引入hover.css
import './assets/styles/hover-min.css'
// 引入animate.css
import 'animate.css';

Vue.config.productionTip = false

// storage
import config from "./config"
import Storage from "vue-ls"

Vue.use(Storage, config.storageOptions)

// request
import http from "./utils/http"

Vue.prototype.$http = http

import charts from './components/charts/BaseCharts'

Vue.component('charts', charts);

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

// element
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"

Vue.use(ElementUI, {size: 'small', zIndex: 100})

// 权限控制
import {hasPermission} from './utils/permission-direct'

const Plugins = [hasPermission];
Plugins.map((plugin) => {
	Vue.use(plugin)
})

new Vue({
	router,
	store,
	mounted() {
		// 同步系统配置
		store.commit('setting/SET_SYSTEM_CONFIGS', {
			menuDirection: Vue.ls.get("menuDirection", config.menuDirection),
			menuTheme: Vue.ls.get("menuTheme", config.menuTheme),
			menuDarkColor: Vue.ls.get("menuDarkColor", config.menuDarkColor),
			primaryColor: Vue.ls.get("primaryColor", config.primaryColor),
			tagsView: Vue.ls.get("tagsView", config.tagsView),
			fixedHeader: Vue.ls.get("fixedHeader", config.fixedHeader),
			leftNavFixed: Vue.ls.get("leftNavFixed", config.leftNavFixed)
		})

		// 设置系统主题色
		const cssText = Vue.ls.get('elementui_style', '');
		if (cssText) {
			const style = document.createElement('style');
			style.id = "create_element_style";
			style.innerText = cssText
			document.head.appendChild(style)
		}
	},
	render: h => h(App)
}).$mount('#app')
