export const hasPermission = {
    install(Vue) {
        Vue.directive('hasPermission', {
            bind(el, binding, vnode) {
                let flag = false;//默认不显示
                let userInfo = vnode.context.$store.state.login.userInfo;
                if(userInfo.perms){
                    let permission = userInfo.perms;
                    let value = binding.value;

                    if (permission.indexOf(value) != -1) {
                        //如果有该权限按钮显示
                        flag = true;
                    }

                    if (!flag) {
                        el.style.display = 'none'
                    }
                }
            }
        })
    }
}
