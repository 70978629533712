import http from "@/utils/http"
import config from "@/config"

export default {
    namespaced: true,
    state: {
        treeList: [],
        activeItem: null
    },
    mutations: {
        TREE_LIST(state, value) {
            state.treeList = value;
        }
    },
    actions: {
        /**
         * 获取部门树
         * @param commit
         * @returns {Promise<unknown>}
         */
        getTree({commit}) {
            return new Promise((resolve) => {
                commit('TREE_LIST', []);
                http.get(`${config.baseUrl}department/getTree`).then((res) => {
                    if (res.code === 200) {
                        commit('TREE_LIST', res.data);
                    }
                    resolve(res);
                })
            })
        },

        /**
         * 新增部门信息
         * @param context
         * @param departmentAddReqVO
         * @returns {Promise<unknown>}
         */
        addDepartment(context, departmentAddReqVO) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}department/addDepartment`, departmentAddReqVO).then((res) => {
                    resolve(res)
                })
            })
        },


        /**
         * 修改部门信息
         * @param context
         * @param departmentEditReqVO
         * @returns {Promise<unknown>}
         */
        modifyDepartment(context, departmentEditReqVO) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}department/editDepartment`, departmentEditReqVO).then((res) => {
                    resolve(res)
                })
            })
        },

        /**
         * 根据Id查询部门信息
         * @param context
         * @param id
         * @returns {Promise<unknown>}
         */
        getDepartmentById(context, id) {
            return new Promise((resolve) => {
                http.get(`${config.baseUrl}department/getDepartmentById/` + id).then((res) => {
                    if (res.code === 200) {
                        resolve(res.data)
                    }
                })
            })
        },

        /**
         * 根据id批量删除部门信息
         * @param context
         * @param ids
         * @returns {Promise<unknown>}
         */
        deleteByIds(context, ids) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}department/deleteById`, ids).then((res) => {
                    resolve(res)
                })
            })
        },
    }
}