import http from "@/utils/http"
import config from "@/config"

export default {
    namespaced: true,
    state: {
        activeItem: null
    },
    mutations: {
        ACTIVE_ITEM(state, value) {
            state.activeItem = value;
        },
    },
    actions: {
        /**
         * 选中列表
         * @param commit
         * @param value
         */
        changeActiveItem({commit}, value) {
            commit('ACTIVE_ITEM', value);
        },

        /**
         * 下载文件
         * @param params
         * @returns {Promise<unknown>}
         */
        downLoad(context, params) {
            return new Promise(() => {
                http.post(`${config.baseUrl}file/downLoad`, params, {
                    responseType: 'blob'
                }).then(resp => {
                    let blob = new Blob([resp])
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob)
                    } else {
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = params.name;
                        link.click()
                        //释放内存
                        window.URL.revokeObjectURL(link.href)
                    }
                })
            })
        },
    }
}