import http from "@/utils/http"
import config from "@/config"
export default {
    namespaced: true,
    state: {
        noticeList: [],
        isValidNoticeList: [],
        activeNoticeItem: null,
        total: 0,
        noticeSum: '',
        noticeDetail: {},
    },
    mutations: {
        SET_NOTICE_LIST(state, value) {
            state.noticeList = value;
        },
        SET_ACTIVE_ITEM(state, value) {
            state.activeNoticeItem = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_NOTICE_SUM(state, value) {
            state.noticeSum = value;
        },
        SET_IS_VALID_NOTICE_LIST(state, value) {
            state.isValidNoticeList = value;
        },
        SET_NOTICE_CONTENT(state, value) {
            state.noticeDetail = value;
        }
    },
    actions: {
        //获取系统公告列表
        getNoticeList({commit}, params) {
            return new Promise((resolve) => {
                commit('SET_NOTICE_LIST', []);
                commit('SET_TOTAL', 0);
                http.get(`${config.baseUrl}sys/notice/getNoticeInfoByPage`, {params}).then((res) => {
                    if (res.code === 200) {
                        commit('SET_NOTICE_LIST', res.data.rows);
                        commit('SET_TOTAL', res.data.total);
                    }
                    resolve(res);
                })
            })
        },

        //获取有效中的公告列表
        getIsValidNoticeList({commit}, params) {
            return new Promise((resolve) => {
                http.get(`${config.baseUrl}sys/notice/getNoticeInfoByPage`, {params}).then((res) => {
                    if (res.code === 200) {
                        commit('SET_IS_VALID_NOTICE_LIST', res.data.rows);
                    }
                    resolve(res);
                })
            })
        },
        //添加系统公告
        addNotice(context, addNoticeData) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/notice/add`, addNoticeData).then((res) => {
                    resolve(res)
                })
            })
        },
        //删除系统公告
        removeNotice(context, id) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/notice/delete`, [id]).then((res) => {
                    resolve(res)
                })
            })
        },
        //修改系统公告
        modifyNotice(context, modifyNoticeData) {
            return new Promise((resolve) => {
                http.post(`${config.baseUrl}sys/notice/update`, modifyNoticeData).then((res) => {
                    resolve(res)
                })
            })
        },
        // 列表选中
        changeActiveNoticeItem({commit}, value) {
            commit('SET_ACTIVE_ITEM', value)
        },
        //获取有效公告总数
        getNoticeSum({commit}, params) {
            return new Promise((resolve) => {
                http.get(`${config.baseUrl}sys/notice/getNoticeValidCount`, {params}).then((res) => {
                    if (res.code === 200) {
                        commit('SET_NOTICE_SUM', res.data);
                    }
                    resolve(res);
                })
            })
        },
        //根据id查询公告表信息
        getNoticeContent({commit}, params) {
            return new Promise((resolve) => {
                http.get(`${config.baseUrl}sys/notice/select/`+params).then((res) => {
                    if (res.code === 200) {
                        commit('SET_NOTICE_CONTENT', res.data);
                    }
                    resolve(res);
                })
            })
        }
    }
}