//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "MenuTree", 
    props: [ "menuList", "firstMenu" ],
    inject: ['reload'],
    computed:{
        menuDirection() {
            return this.$store.state.setting.systemConfig.menuDirection
        }
    },
    data() {
        return {
            activeMenu: {}
        }
    },
    methods: {
        handlerClickMenu(item) {
            // 如果点击的菜单和当前的相同，就刷新当前页面
            if (this.activeMenu.id === item.id) {
                this.reload();
            }
            setTimeout(() => {
                this.activeMenu = item;
            }, 200);
        },
    }
};
