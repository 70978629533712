/**
 * 配置文件
 * 此处要用module.export 不然在 vue.config.js 中无法调用
 */
 const TDT_TOKEN = [
    'e622ecef5fd38e28fe742004a1efd141',
    '913e71eae25abec2c8c2abe95c5f2198',
    'c6e9de02132b55cdfa924b28a77150d0',
    'd22eb44002066bc83077ca6692f8865e',
    'a156cec05e9b7b10067ceadb1b85e885',
    'f0e90d78280d379cb49dd6891723afeb',
    '3192866a6921434670348259ff195e41',
    '0db8e558f01c73937ca89bb1f44e98ed',
    '0f1f5b5d4a81979d8437765b34444489',
    '135bc3444441dcce8925a45fc825c669',
];
const index = Math.floor(Math.random() * TDT_TOKEN.length);
module.exports = {
    // 网站名称
    title: '五佳智慧农业服务管控平台',

    // 菜单名称

    // 本地存储配置
    storageOptions: {
        namespace: 'qy__', // key prefix
        name: 'ls', // name variable Vue.[ls] or this.[$ls],
        storage: 'local', // storage name session, local, memory
    },

    menuTitle: '五佳智慧农业服务管控平台',
    // 请求地址http://
    baseUrl: process.env.NODE_ENV === 'production' ? 'http://139.186.204.247:15082/' : 'http://139.186.204.247:15082/',

    // 上传附件回显nginx映射地址
    imageUrl: process.env.NODE_ENV === 'production' ? 'http://139.186.204.247:8080/image/' : 'http://127.0.0.1:8634/file/',

    // qy-geo-api
    qyGeoApi: 'http://spaceapi.quanyanqy.com/qy-geo/api',

    // 天地图token
    tdtToken: TDT_TOKEN[index],
    
    // 菜单方向， vertical horizontal
    menuDirection: 'horizontal',

    // 菜单主题  dark  light
    menuTheme: 'dark',

    // 深色菜单的颜色
    menuDarkColor: '#002140',

    // 系统主题色
    primaryColor: '#1890ff',

    //默认多页签模式
    tagsView: false,

    // 固定顶部
    fixedHeader: true,

    // 默认菜单固定顶部
    leftNavFixed: false,

}
