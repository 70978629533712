//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "personal-information",
    computed: {
        activeUserInfo() {
            return this.$store.state['user'].activeUserInfo;
        }
    },
    inject: ['showLoading', 'hideLoading'],
    async mounted() {
        await this.$store.dispatch('user/getActiveUserInfo');
    }
}
