import http from "@/utils/http"
import config from "@/config"

export  default {
    namespaced: true,
    state: {
        list: {},
    },
    mutations: {
        SET_LIST(state, value) {
            state.list = value;
        },
    },
    actions:{
        /**
         * 查询数据
         * @param commit
         * @param state
         * @returns {Promise<unknown>}
         */
        search({commit},queryParam) {
            return new Promise((resolve) => {
                commit('SET_LIST', {});
                http.post(`${config.baseUrl}` + 'model/getResult', queryParam)
                    .then((res) => {
                        if (res.code === 200) {
                            commit('SET_LIST', res.data);
                        }
                        resolve(res);
                    })
            })
        },
    }
}