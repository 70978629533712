import http from "@/utils/http"
import config from "@/config"

export default {
	namespaced: true,
	state: {
		list: [],
		pointerName: '',
		queryParams: {
			type: '',
			year: [],
			grade: '',
			varietiesType: [],
			areaCode: [],
			pointerType: '',
			index: []
		},
		colorList: [['#09e9f3', '#99f4ff'],
			['#8d2efe', '#8983ff'],
			['#28b1ff', '#62a8fd'],
			['#f7fc07', '#f2f5a8'],
			['#fe9834', '#e1a161'],
			['#FF359A', '#FFD9EC'],
			['#FF5151', '#FFD2D2'],
			['#02DF82', '#E8FFF5'],
			['#AD5A5A', '#EBD6D6'],
			['#9AFF02', '#DEFFAC'],
			['#FFFF66', '#FFFFF4'],
			['#66FFFF', '#F5FFE8']
		],
	},
	mutations: {
		SET_QUERY_PARAMS(state, value) {
			state.queryParams = value;
		},
		SET_LIST(state, value) {
			state.list = value;
		},
		SET_POINTER_NAME(state, value) {
			state.pointerName = value;
		},
	},
	actions: {
		/**
		 * 更改参数
		 * @param commit
		 * @param value
		 */
		changeParams({commit}, value) {
			commit('SET_QUERY_PARAMS', value);
		},

		/**
		 * 更改指标的名称
		 * @param state
		 * @param key
		 * @param value
		 */
		changePointerName({commit}, value) {
			commit('SET_POINTER_NAME', value);
		},

		/**
		 * 更改条件对象的某个值
		 * @param state
		 * @param key
		 * @param value
		 */
		changeParamsSingle({state}, {key, value}) {
			state.queryParams[key] = value;
		},


		/**
		 * 查询数据
		 * @param commit
		 * @param state
		 * @param url
		 * @returns {Promise<unknown>}
		 */
		search({commit, state}) {
			return new Promise((resolve) => {
				commit('SET_LIST', []);
				http.post(`${config.baseUrl}` + 'dataManagement/GetEcharts', state.queryParams)
					.then((res) => {
						if (res.code === 200) {
							commit('SET_LIST', res.data);
						}
						resolve(res);
					})
			})
		},
	}
}
