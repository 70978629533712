export default {
    namespaced: true,
    state: {
        selectedRegionObject: {}, // 选中的行政区划对象，用于数据查询
        selectedRegionGeojson: {}, //选中区域的geojson
        realRegionObject: {}, //实际的区域对象，用于下钻操作
        selectedTobaccoArea: {}, // 选中的种植区
        regionType: 'area', // 选中的区域类型
    },
    mutations: {
        SET_SELECTED_REGION_OBJECT(state, value) {
            state.selectedRegionObject = value;
        },
        SET_SELECTED_REGION_GEOJSON(state, value) {
            state.selectedRegionGeojson = value;
        },
        SET_REAL_REGION_OBJECT(state, value) {
            state.realRegionObject = value;
        },
        SET_SELECTED_TOBACCO_AREA(state, value) {
            state.selectedTobaccoArea = value;
        },
        SET_REGION_TYPE(state, value) {
            state.regionType = value;
        }
    },
    actions: {
        setSelectedRegionObject({ commit }, value) {
            commit('SET_SELECTED_REGION_OBJECT', value);
        },
        setSelectedRegionGeojson({ commit }, value) {
            commit('SET_SELECTED_REGION_GEOJSON', value);
        },
        setRealRegionObject({commit}, value) {
            commit('SET_REAL_REGION_OBJECT', value);
        },
        setSelectedTobaccoArea({commit}, value) {
            commit('SET_SELECTED_TOBACCO_AREA', value);
        },
        setRegionType({commit}, value) {
            commit('SET_REGION_TYPE', value);
        }
    },
};
