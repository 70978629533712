//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollPane from "./ScrollPane";
export default {
	components: { ScrollPane },
	data() {
		return {};
	},
	computed: {
		visitedViews() {
			return this.$store.state.tagsView.visitedViews;
		},
            
        tagsViewLength() {
            return this.$store.state.tagsView.visitedViews.length
        },

        primaryColor() {
			return this.$store.state.setting.systemConfig.primaryColor;
		},
	},
	watch: {
		$route() {
			this.addTags();
			this.moveToCurrentTag();
		},
	},
	mounted() {
		this.addTags();
	},
	methods: {
		isActive(route) {
			return route.path === this.$route.path;
		},
		isAffix(tag) {
			return tag.meta && tag.meta.affix;
		},
		addTags() {
			const { meta } = this.$route;
			if (meta && meta.title) {
				this.$store.dispatch("tagsView/addView", this.$route);
			}
			return false;
		},
		moveToCurrentTag() {
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.path === this.$route.path) {
						this.$refs.scrollPane.moveToTarget(tag);
						// when query is different then update
						if (tag.to.fullPath !== this.$route.fullPath) {
							this.$store.dispatch(
								"tagsView/updateVisitedView",
								this.$route
							);
						}
						break;
					}
				}
			});
		},

		closeSelectedTag(view) {
            this.$store
                .dispatch("tagsView/delView", view)
                .then(({ visitedViews }) => {
                    if (this.isActive(view)) {
                        this.toLastView(visitedViews);
                    }
                });
		},
		toLastView(visitedViews) {
			const latestView = visitedViews.slice(-1)[0];
			if (latestView) {
				this.$router.push(latestView.fullPath);
			} else {
				this.$router.push("/");
			}
		},
	},
};
