//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name:'BaseLoading',
    props: {
        msg: {
            type: String,
            default: '正在加载'
        },
        loadingContainerBackgroundColor: {
            type: String,
            default: '#dedede',
        }
    }
}
